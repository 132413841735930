
import { KubernetesProvider, KubernetesProviderColors, KubernetesProviderKeys } from "./types/kubernetes-provider";
import apiClient from "@/services/api-client";
import exportService from "@/core-ui/services/export-service";
import Code from "@/core-ui/Code.vue";
import { computed, defineComponent, onMounted, PropType, reactive, ref } from "vue";
import { useToast } from "vue-toastification";
import { Env, isSaas, isStagingOrTestOrLocalEnv } from "@/helpers/is-production-env";
import { ClusterLocation, ClusterLocationColors, ClusterLocationKeys } from "@/components/clusters/types/cluster-location";
import ClusterConfigSelector from "@/components/clusters/ClusterConfigSelector.vue";
import clustersService from "@/services/clusters-service";

export default defineComponent({
    components: {
        ClusterConfigSelector,
        Code,
    },
    props: {
        grafanaAuth: Object as PropType<{
            grafanaMetricsUser: string;
            grafanaMetricsViewToken: string;
            grafanaMetricsWriterToken: string;
        }>,
        kubeUser: Object as PropType<{ password: string; email: string }>,
        clusterId: String,
        clusterName: String,
    },
    setup(props, ctx) {
        const clusterLocationOptions = [ClusterLocation.LOCAL, ClusterLocation.REMOTE];
        const kubernetesProviderOptions = [KubernetesProvider.ON_PREMISE, KubernetesProvider.AWS, KubernetesProvider.AKS, KubernetesProvider.GCP, KubernetesProvider.OPENSHIFT]
        const cloudType = ref<string>(null as any);
        const clusterDomainError = ref<string>(null as any);
        const toast = useToast();
        const isSaasEnv = computed(() => isSaas());
        const clusterLocation = ref<string>("")
        const clusterDomain = ref<string>("")
        const selectedVersion = ref<string>();
        let versionsList = reactive({items:[] as string[]});
        let operatorVersionsMap = reactive({} as Record<string, string>);
        const isNotProdEnv = isStagingOrTestOrLocalEnv();

        const steps = computed(() =>
            Object.fromEntries(
                ["chooseTarget", "clusterLocation", "operatorVersion", "clusterDomain", "storeCertificateAsSecret", "downloadValues", "customizeValues", "installHelm", "installRunai"]
                    .filter((s) => (s != "clusterDomain" && s != "storeCertificateAsSecret") || isSaasEnv.value || clusterLocation.value === "remote")
                    .map((s, i) => [s, `${i + 1}.`]),
            ),
        );

        onMounted(async () => {
            const chartEnv = isNotProdEnv ? Env.Staging : Env.Cloud;
            operatorVersionsMap = await clustersService.getOperatorVersions(chartEnv);
            versionsList.items = Object.keys(operatorVersionsMap);
        });

        const self = {
            versionsList,
            operatorVersionsMap,
            cloudType,
            clusterLocation,
            clusterDomain,
            isSaasEnv,
            steps,
            clusterDomainError,
            selectedVersion,
            ClusterLocationColors,
            clusterLocationOptions,
            KubernetesProviderColors,
            kubernetesProviderOptions,
            isNotProdEnv,

            get url() {
                let url = `v1/k8s/clusters/${props.clusterId}/installfile?cloud=${cloudType.value}`;
                if (self.clusterDomain.value) {
                    const clusterIP = encodeURIComponent(self.clusterDomain.value);
                    url = `${url}&clusterip=${clusterIP}`;
                }
                return url;
            },

            get operatorFileName() {
                return `runai-${props.clusterName}.yaml`;
            },

            get code() {
                return [
                    `curl '${location.origin}/${self.url}' `,
                    `--header 'Authorization: Bearer ${apiClient.access_token}' > ${self.operatorFileName}`,
                ].join("");
            },

            async downloadYaml() {
                try {
                    const data = await apiClient.request(self.url, "GET");
                    const dataAsText = await data.text();
                    exportService.toDownload(`runai-operator-${props.clusterName}`, "yaml", dataAsText);
                } catch (err) {
                    toast.error("Failed to download!");
                }
            },

            providerChanged(provider: KubernetesProvider) {
                cloudType.value = KubernetesProviderKeys[provider];
            },

            clusterLocationChanged(location: ClusterLocation) {
                clusterLocation.value = ClusterLocationKeys[location]
            },

            operatorVersionChanged(operatorVersion: string) {
                selectedVersion.value = operatorVersionsMap[operatorVersion];
            },

            clusterUrlChanged() {
                if (clusterDomain.value) {
                    try {
                        const clusterUrl = new URL(clusterDomain.value)
                        if (clusterUrl.protocol != "https:") {
                            clusterDomainError.value = `Please use https protocol. ${clusterUrl.protocol} is not supported`
                        } else if (clusterUrl.pathname != "/") {
                            clusterDomainError.value = `Please use domain-only url (remove the path: ${clusterUrl.pathname}`
                        } else {
                            clusterDomainError.value = ""
                        }
                    } catch (e) {
                        // taken from here: https://melvingeorge.me/blog/check-if-string-is-valid-ip-address-javascript
                        const ipv4Regex = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi;
                        const isIp = ipv4Regex.test(clusterDomain.value)
                        if (isIp) {
                            clusterDomainError.value = ""
                        } else {
                            clusterDomainError.value = "Please enter a valid URL (e.g https://www.example.com)"
                        }
                    }
                } else {
                    clusterDomainError.value = ""
                }
            }
        };
        return self;
    },
});
