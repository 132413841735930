import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _mergeProps(_ctx.$attrs, { ref: "contentRef" }), [
    _createElementVNode("code", {
      class: _normalizeClass({ wrap: _ctx.isCodeExtended }),
      ref: "codeRef",
      onMousemove: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onCodeResize()))
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 34),
    _createElementVNode("i", {
      class: "icon raicon-copy",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.copy && _ctx.copy(...args)))
    })
  ], 16))
}