
import Button from "@/core-ui/Button.vue";
import { dispatcher, RequestState } from "@/core-ui/services/dispatcher";
import InstallClusterDescription from "./InstallClusterDescription.vue";
import Modal from "@/core-ui/Modal.vue";
import tenantService from "@/services/tenant-service";
import { defineComponent, PropType, Ref } from "vue";

export default defineComponent({
    props: {
        cluster: Object,
    },
    emits: ["close"],

    components: { Modal, InstallClusterDescription, Button },
    setup(props, ctx) {
        const self = {
            tenant: dispatcher.dispatch(
                Promise.all([tenantService.getTenantData(), tenantService.getTenantKubeUser()]).then(
                    ([{ grafanaMetricsAuth }, kubeUser]) => ({
                        grafanaMetricsAuth,
                        kubeUser: kubeUser || {},
                    }),
                ),
            ) as RequestState,

            get grafanaAuth(): {
                grafanaMetricsUser: string;
                grafanaMetricsViewToken: string;
                grafanaMetricsWriterToken: string;
            } {
                return self.tenant.results ? self.tenant.results.grafanaMetricsAuth : {};
            },

            get kubeUser() {
                return self.tenant.results ? self.tenant.results.kubeUser : {};
            },

            close() {
                ctx.emit("close");
            },
        };
        return self;
    },
});
