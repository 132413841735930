import { DataGridModelProps, createModelMeta } from "@/core-ui/data-grid/compositions/DataGridModel";
import { DataGridColumn } from "@/core-ui/types/column";
import clustersService from "@/services/clusters-service";
import { dateFormat } from "@/core-ui/helpers/data-formats";
import StatusLabel from "@/core-ui/StatusLabel.vue";
import { Cluster } from "@/types/cluster";
import {AddDefaultSort, OrderDirection, SortLocalStorage} from "@/core-ui/data-grid/compositions";
import {computed} from "vue";

export const columns: DataGridColumn[] = [
    {
        key: "name",
        label: "Cluster Name",
        dataKey: "name",
        searchable: true,
        sortable: true,
        display: {
            table: {
                width: 280,
            },
        },
    },
    {
        key: "status",
        label: "Status",
        dataKey: "status",
        searchable: true,
        sortable: true,
        display: {
            component: StatusLabel,
            table: {
                width: 120,
            },
        },
    },
    {
        key: "uuid",
        primary: true,
        label: "Cluster UUID",
        dataKey: "uuid",
        searchable: true,
        display: {
            table: {
                width: 330,
            },
        },
    },
    {
        key: "created",
        primary: true,
        label: "Created",
        dataKey: "createdAt",
        searchable: true,
        sortable: true,
        dataTransform: dateFormat,
        display: {
            table: {
                width: 170,
            },
        },
    },
    {
        key: "url",
        label: "URL",
        dataKey: "domain",
        display: {
            table: {
                width: 350,
            },
        },
    },
    {
        key: "version",
        label: "Version",
        dataKey: "version",
        searchable: true,
        sortable: true,
        display: {
            table: {
                width: 120,
            },
        },
    },
];

export type ModalType = Cluster;
const type = "cluster";
export const meta = createModelMeta<ModalType>({
    icon: `raicon-${type}`,
    type,
    display: (item) => item.name,
});

export const createModelProps = (props: { dataKey: string }): DataGridModelProps<ModalType> => {
    const computedColumns = computed(() => AddDefaultSort(columns, type, 'name'));
    return ({
        get tableName() {
            return type;
        },
        get dataKey() {
            return props.dataKey;
        },
        meta,
        columns: computedColumns.value,
        syncServer: false,
        fetchInterval: 60000,
        fetch: async (filter) => {
            const data = await clustersService.query();
            return {data};
        },
    })
};
