export enum KubernetesProvider {
    ON_PREMISE = "On-Premises",
    AWS = "Amazon Elastic Kubernetes Service",
    AKS = "Azure Kubernetes Service",
    GCP = "Google Kubernetes Engine",
    OPENSHIFT = "OpenShift",
}

export const KubernetesProviderKeys = {
    [KubernetesProvider.GCP]: "gcp",
    [KubernetesProvider.AWS]: "aws",
    [KubernetesProvider.AKS]: "aks",
    [KubernetesProvider.ON_PREMISE]: "op",
    [KubernetesProvider.OPENSHIFT]: "openshift",
};

export const KubernetesProviderColors = {
    [KubernetesProvider.ON_PREMISE]: "#707070",
    [KubernetesProvider.GCP]: "#5856d6",
    [KubernetesProvider.AWS]: "#ff9500",
    [KubernetesProvider.AKS]: "#006400",
    [KubernetesProvider.OPENSHIFT]: "#ff0000",
};
