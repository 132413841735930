
import { defineComponent, ref, watch } from "vue";

export enum LabelType {
    SUCCESS = "Succeeded",
    ERROR = "Failed",
    RUNNING = "Running",
    COMPLETE = "Complete",
    PROGRESSING = "Progressing",
    PENDING = "Pending",
    DELETED = "Deleted",
    SUSPENDED = "Suspended",
    SWAPPED = "Swapped",
}

export const LabelTypeOrder = {
    [LabelType.COMPLETE]: 1,
    [LabelType.RUNNING]: 2,
    [LabelType.SWAPPED]: 3,
    [LabelType.PENDING]: 4,
    [LabelType.PROGRESSING]: 5,
    [LabelType.SUSPENDED]: 6,
    [LabelType.SUCCESS]: 7,
    [LabelType.DELETED]: 8,
    [LabelType.ERROR]: 9,
};

export default defineComponent({
    props: {
        value: {},
        showTitle: {
            type:Boolean,
            default:true
        }
    },
    created() {
        this.style = (this as any).$style;
    },
    setup(props) {
        const info = ref();
        const style = ref<any>();
        watch(
            () => props.value,
            async () => {
                let realValue: any = props.value;
                // if the value is a promise, we sould wait for its complitaion, and in the meantime present "Unknown"
                if (props.value instanceof Promise) {
                    try {
                        // at the first time set it to Unknown - todo: set it to loading
                        if (!info.value) {
                            info.value = { status: "Unknown" };
                        }
                        realValue = await props.value;
                    } catch (e) {
                        info.value = { status: "Unknown" };
                        return;
                    }
                }
                if ((realValue || "").indexOf("|") == -1) {
                    info.value = { status: realValue };
                    return;
                }
                const [_, status, percentage] = (realValue || "").split("|");
                info.value = {
                    status,
                    percentage: percentage ? Number(percentage).toFixed(2) + "%" : null,
                };
            },
            { immediate: true },
        );

        const self = {
            style,
            get showProgress() {
                return info.value.status === LabelType.RUNNING && info.value.percentage;
            },

            get display() {
                if (this.showProgress) {
                    return info.value.percentage;
                }
                return info.value.status || "-";
            },

            get labelTitle(): string{
                return props.showTitle ? this.display : ''
            },

            get progressStyle() {
                return this.showProgress ? { width: info.value.percentage } : null;
            },

            get classes() {
                let statusClass = info.value.status;
                if (!info.value.status) {
                    statusClass = "Unknown";
                }

                if (statusClass.includes("Error") || statusClass.includes("BackOff")) {
                    statusClass = "Error";
                }

                if (statusClass.startsWith("Init") || statusClass.startsWith("Running")) {
                    statusClass = "Running";
                }
                switch (statusClass) {
                    case "Complete":
                    case "Running":
                    case "Terminating":
                    case "ContainerCreating":
                    case "PodInitializing":
                        statusClass = "Running";
                        break;
                    case "Progressing":
                        statusClass = "Pending";
                        break;
                    case "Pending":
                        break;
                    case "Succeeded":
                        break;
                    case "TimedOut":
                    case "Preempted":
                    case "Deleted":
                        statusClass = "Deleted";
                        break;
                    case "ContainerCannotRun":
                    case "Error":
                    case "Fail":
                    case "ErrImagePull":
                        statusClass = "Failed";
                        break;
                }
                return [style.value.progress, style.value[statusClass]];
            },
        };
        return self;
    },
});
