import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InstallClusterDescription = _resolveComponent("InstallClusterDescription")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    size: "lg",
    title: "Install Run:AI on a Cluster",
    onCancel: _ctx.close
  }, {
    body: _withCtx(() => [
      _createVNode(_component_InstallClusterDescription, {
        "cluster-id": _ctx.cluster?.uuid,
        "cluster-name": _ctx.cluster?.name,
        "grafana-auth": _ctx.grafanaAuth,
        "kube-user": _ctx.kubeUser
      }, null, 8, ["cluster-id", "cluster-name", "grafana-auth", "kube-user"])
    ]),
    buttons: _withCtx(() => [
      _createVNode(_component_Button, {
        text: "Close",
        type: "button",
        onClick: _ctx.close,
        aid: "install-claster-modal-close-btn"
      }, null, 8, ["onClick"])
    ]),
    _: 1
  }, 8, ["onCancel"]))
}