import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.statusLabel)
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.classes),
      style: _normalizeStyle(_ctx.progressStyle)
    }, null, 6),
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.$style.label),
      title: _ctx.labelTitle
    }, _toDisplayString(_ctx.display), 11, _hoisted_1)
  ], 2))
}