<template>
    <i class="raicon-loader" :class="$style.spinner"></i>
</template>
<style lang="scss" module>
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.spinner {
    animation: spin 1s linear infinite;
    display: inline-block;
    font-size: 120%;
    margin-top: -10%;
}
</style>
