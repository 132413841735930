import clustersStore from "@/stores/clusters-store";
import { nameValidation } from "@/core-ui/helpers/validations-schemas";
import { Validation } from "@/core-ui/types/validation";
import { ref } from "vue";

export function useClusterNameValidation(): Validation {
    const isClusterNameExisted = ref(false);
    return {
        ...nameValidation,
        test(v: string) {
            isClusterNameExisted.value = !!clustersStore.state.clusters.find((cluster) => cluster.name === v);

            return !isClusterNameExisted.value && (nameValidation as any).test(v);
        },
        get description() {
            return isClusterNameExisted.value ? "Cluster with this name already exists" : nameValidation.description;
        },
    };
}
