export enum ClusterLocation {
    LOCAL = "Same as Control Plane",
    REMOTE = "Remote to Control Plane",
}

export const ClusterLocationKeys = {
    [ClusterLocation.LOCAL]: "local",
    [ClusterLocation.REMOTE]: "remote",
};

export const ClusterLocationColors = {
    [ClusterLocation.LOCAL]: "#707070",
    [ClusterLocation.REMOTE]: "#5856d6",
};
