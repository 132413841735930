
import Button from "@/core-ui/Button.vue";
import Modal from "@/core-ui/Modal.vue";
import InputField from "@/core-ui/forms/components/InputField.vue";
import { dispatcher, RequestState } from "@/core-ui/services/dispatcher";
import CircleLoader from "@/core-ui/CircleLoader.vue";
import InstallClusterDescription from "./InstallClusterDescription.vue";
import WizardSteps from "./WizardSteps.vue";
import tenantService from "@/services/tenant-service";
import { STEPS, useCreateClusterWizard } from "@/compositions/CreateClusterWizard";
import { defineComponent, ref } from "vue";
import { useClusterNameValidation } from "./clusterNameValidation";

export default defineComponent({
    components: {
        WizardSteps,
        InputField,
        Modal,
        Button,
        CircleLoader,
        InstallClusterDescription,
    },
    emits: ["cancel", "done"],
    props: { force: Boolean },
    setup: (props, ctx) => {
        const pendingCluster = ref(null);

        const self = {
            state: useCreateClusterWizard(),
            steps: STEPS,
            pendingCluster,
            nameValidation: useClusterNameValidation(),

            // if enableing to close the dialog

            get step() {
                return this.state.status.step;
            },

            isStep(step: STEPS) {
                return this.step == step;
            },

            tenant: dispatcher.dispatch(
                Promise.all([tenantService.getTenantData(), tenantService.getTenantKubeUser()]).then(
                    ([{ grafanaMetricsAuth }, kubeUser]) => ({
                        grafanaMetricsAuth,
                        kubeUser: kubeUser || {},
                    }),
                ),
            ) as RequestState,

            get wizardTitle() {
                return `Add Your${props.force ? " First" : ""} Cluster`;
            },

            get clusterName() {
                return self.state.status.cluster.name;
            },

            get clusterUuid() {
                return self.state.status.cluster.uuid;
            },

            set clusterName(value) {
                self.state.status.cluster.name = value;
            },

            next() {
                self.state.next();
            },

            get grafanaAuth(): {
                grafanaMetricsUser: string;
                grafanaMetricsViewToken: string;
                grafanaMetricsWriterToken: string;
            } {
                return self.tenant.results ? self.tenant.results.grafanaMetricsAuth : {};
            },

            get kubeUser() {
                return self.tenant.results ? self.tenant.results.kubeUser : {};
            },

            get isShowNextBun() {
                return self.state.isNext && (!props.force || self.step !== STEPS.CHECK_ACTIVE);
            },

            get btnText() {
                switch (self.step) {
                    case STEPS.ADD_CLUSTER:
                        return "Create";
                    case STEPS.CHECK_ACTIVE:
                        return "Done";
                    default:
                        return "Next";
                }
            },

            cancel() {
                if (!props.force && self.step !== STEPS.DONE) {
                    ctx.emit("cancel", self.state.status.cluster.uuid ? self.state.status.cluster : undefined);
                } else {
                    ctx.emit("done", self.state.status.cluster);
                }
            },
        };
        return self;
    },
});
