
import RaMenu from "@/core-ui/RaMenu.vue";
import { PropType, defineComponent, ref } from "vue";

export default defineComponent({
    components: { RaMenu },
    emits: ["change"],
    props: {
        placeholder: String,
        options: Object as PropType<any>,
        colors: [] as PropType<any>,
    },
    setup(props, ctx) {
        const selected = ref<any>();
        return {
            selected,
            select(option: any) {
                selected.value = option;
                ctx.emit("change", selected.value);
            },
        };
    },
});
