import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CreateClusterWizardModal = _resolveComponent("CreateClusterWizardModal")!
  const _component_DeleteModalAction = _resolveComponent("DeleteModalAction")!
  const _component_InstallClusterModal = _resolveComponent("InstallClusterModal")!
  const _component_DataGrid = _resolveComponent("DataGrid")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, null, {
    default: _withCtx(() => [
      (_ctx.createModal.isOpening)
        ? (_openBlock(), _createBlock(_component_CreateClusterWizardModal, {
            key: 0,
            onDone: _ctx.createModal.submit,
            onCancel: _ctx.createModal.cancel
          }, null, 8, ["onDone", "onCancel"]))
        : _createCommentVNode("", true),
      _createVNode(_component_DeleteModalAction, { state: _ctx.deleteModal }, null, 8, ["state"]),
      (_ctx.installModal.isOpening)
        ? (_openBlock(), _createBlock(_component_InstallClusterModal, {
            key: 1,
            cluster: _ctx.installModal.handleData,
            onClose: _ctx.installModal.cancel
          }, null, 8, ["cluster", "onClose"]))
        : _createCommentVNode("", true),
      _createVNode(_component_DataGrid, {
        model: _ctx.model,
        actions: _ctx.actions,
        "sync-url": true
      }, null, 8, ["model", "actions"])
    ]),
    _: 1
  }))
}