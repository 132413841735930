import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.wizardSteps)
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.steps, (index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: _normalizeClass({
                [_ctx.$style.step]: true,
                [_ctx.$style.active]: index === $props.current,
                [_ctx.$style.passed]: index < $props.current,
            })
      }, _toDisplayString(index), 3))
    }), 128))
  ], 2))
}