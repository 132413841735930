
import Page from '@/core-ui/Page.vue';
import authStore from '@/stores/authStore';
import DataGrid from '@/core-ui/data-grid/components/DataGrid.vue';
import DeleteModalAction from '@/core-ui/data-grid/components/DeleteModal/DeleteModalAction.vue';

import {
    useDataGridModel,
    createCRUDActions,
    ActionOn,
    useDeleteModalAction,
    useModalAction,
} from '@/core-ui/data-grid/compositions';
import { createModelProps, meta, ModalType as Item } from '@/models/clusters.model';
import CreateClusterWizardModal from '../components/clusters/CreateClusterWizardModal.vue';
import InstallClusterModal from '../components/clusters/InstallClusterModal.vue';
import clustersService from '../services/clusters-service';
import { defineComponent } from 'vue';

export default defineComponent({
    components: {
        Page,
        DataGrid,
        DeleteModalAction,
        CreateClusterWizardModal,
        InstallClusterModal,
    },
    setup() {
        async function deleteItems(itemsToDelete: any[]) {
            if (!itemsToDelete) return;
            return clustersService.remove(itemsToDelete[0].uuid);
        }

        const model = useDataGridModel(createModelProps({ dataKey: meta.type }));

        const installModal = useModalAction({
            submit: model.refresh,
            mapHandleData: (item = {}) => ({ ...item }),
        });

        const createModal = useModalAction({
            submit: model.refresh,
            mapHandleData: (item = {}) => ({ ...item }),
        });

        const deleteModal = useDeleteModalAction<Item>({
            note: 'Any associated data, such as projects, departments, and job history, will be deleted and can not be restored.',
            modelMeta: meta,
            delete: (props) =>
                deleteItems(props).then((d) => {
                    model.refresh();
                    return d;
                }),
        });

        function permitted() {
            return authStore.userInfo.roles.includes('admin');
        }

        const self = {
            model,
            createModal,
            installModal,
            deleteModal,

            actions: [
                ...createCRUDActions(
                    meta,
                    {
                        create: createModal.handle,
                        remove: deleteModal.handle as any,
                    },
                    permitted,
                ),
                {
                    on: ActionOn.Item,
                    permitted: permitted,
                    icon: 'raicon-install',
                    key: 'install',
                    label: 'Install',
                    aid: 'install-Cluster',
                    func: installModal.handle,
                },
            ],
        };
        return self;
    },
});
