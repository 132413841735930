import { reactive } from "vue";
import { useIntervalInvoker } from "@/core-ui/data-grid/compositions/IntervalInvoker";
import clustersService from "@/services/clusters-service";

export enum STEPS {
    ADD_CLUSTER = 1,
    SHOW_COMMAND = 2,
    CHECK_ACTIVE = 3,
    DONE = 4,
}

type Status = {
    step: STEPS;
    cluster: { name: string; uuid: string };
    submitted: boolean;
    activated: boolean;
    saved: boolean;
};

export type UsingCreateClusterWizard = {
    back(): void;
    next(): void;
    status: Status;
    isBack: boolean;
    isNext: boolean;
};

export function useCreateClusterWizard(): UsingCreateClusterWizard {
    const status: Status = reactive({
        step: STEPS.ADD_CLUSTER,
        cluster: { name: "", uuid: "" },
        submitted: false,
        activated: false,
        saved: false,
    });

    const invoker = useIntervalInvoker({ func: check, interval: 5000 });

    async function saveCluster() {
        try {
            status.submitted = true;
            const { uuid, name } = status.cluster;
            // is already saved just update the cluster name
            if (uuid) {
                status.cluster = await clustersService.update(uuid, name);
            } else {
                status.cluster = await clustersService.create(name);
            }
            status.saved = true;
        } finally {
            status.submitted = false;
        }
    }

    async function check() {
        try {
            const cid = status.cluster.uuid;
            const isActive = (await clustersService.checkClusters(cid))[cid];
            if (isActive) {
                status.activated = isActive;
                next();
                return;
            }
        } catch (e) {
            //
        }
    }

    function back() {
        if (status.step !== STEPS.ADD_CLUSTER) (status.step as number)--;
    }

    async function next() {
        let next;
        if (status.step !== STEPS.DONE) next = (status.step as number) + 1;
        switch (next) {
            case STEPS.SHOW_COMMAND:
                await saveCluster();
                break;
            case STEPS.CHECK_ACTIVE:
                invoker.start();
                break;
            case STEPS.DONE:
                invoker.stop();
                break;
        }
        status.step = next as STEPS;
    }

    return {
        status,
        back,
        next,
        get isBack() {
            return status.step !== STEPS.ADD_CLUSTER && status.step !== STEPS.DONE;
        },
        get isNext() {
            return status.step !== STEPS.DONE;
        },
    };
}
