
import { copy } from "@/core-ui/helpers/copy";
import { useToast } from "vue-toastification";
import {defineComponent, PropType, ref} from "vue";

export default defineComponent({
  props: {
    isCodeResizeOnHover: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
  },
    setup: (props) => {
        const codeHeight = ref(30);
        const contentRef = ref<HTMLElement | null>(null);
        const codeRef = ref<HTMLElement | null>(null);
        const toast = useToast();

        return {
            codeHeight,
            contentRef,
            codeRef,
            copy() {
                const text = (contentRef.value as HTMLElement).innerText as string;
                copy(text);
                toast.info("Copied!!");
            },

            onCodeResize() {
                codeHeight.value = (codeRef.value as HTMLElement).clientHeight;
            },

            get isCodeExtended() {
              if (props.isCodeResizeOnHover || !codeRef.value) {
                return codeHeight.value !== 30;
              }
              return (codeRef.value as HTMLElement).clientHeight
            },
        };
    },
});
